<template>
  <div class="message-search">
    <MessageSearchHeader
      :keyword="keyword"
      v-model="showSearch"
      @show-search="changeShowSearchStatus"
      @keyword="updateKeyword"
    />
    <div class="message-search__content">
      <p class="f-w6 text-left _title">検索履歴</p>
      <MessageSearchItem v-for="index in 4" :key="index" />
    </div>
  </div>
</template>

<script>
import MessageSearchHeader from "./MessageSearchHeader.vue";
import MessageSearchItem from "./MessageSearchItem.vue";
export default {
  components: { MessageSearchItem, MessageSearchHeader },
  name: "MessageSearch",
  data() {
    return {
      keyword: ""
    };
  },
  props: {
    showSearch: {
      type: Boolean
    }
  },
  methods: {
    handleSearch() {
      console.log(this.keyword);
    },
    changeShowSearchStatus() {
      this.$emit("show-search");
    },
    updateKeyword(value) {
      this.keyword = value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_fontFamily.scss";

.message-search {
  background-color: #f8f8f8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 80;
  min-height: 100vh;
  &-item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__content {
    padding: 0 19px;
    margin-top: 15px;

    ._title {
      margin-bottom: 10px;
      color: #000000;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
