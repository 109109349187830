<template>
  <div>
    <AppContainer
      :useBackground="!isLoading ? true : false"
      :useShadow="!isLoading ? true : false"
    >
      <template v-if="isLoading">
        <AppLoading />
      </template>
      <template v-else>
        <div class="ms-layout">
          <HeaderCommon
            ref="header"
            title="メッセージ一覧"
            v-model="showSearch"
            @show-search="changeShowSearchStatus"
          />
          <div
            id="content-scroll"
            class="content-scroll"
            :style="[
              firebaseListRoom.length > 0 && checkNoMsg == 1
                ? { height: 'calc(100vh - 64px)' }
                : ''
            ]"
          >
            <div class="filter-message">
              <MessageFilter @emitType="chooseType($event)" />
            </div>
            <hr
              class="mt-0 mb-0 divide"
              v-if="firebaseListRoom.length > 0 && checkNoMsg == 1"
            />
            <div
              class="list-message"
              v-if="firebaseListRoom.length > 0 && checkNoMsg == 1"
            >
              <!-- data for message  -->
              <div v-for="(data, index) in firebaseListRoom" :key="index">
                <MessageListItem
                  v-if="
                    !(
                      (data.type === 2 &&
                        data.members &&
                        data.members[0] &&
                        listUserIdsBlock.includes(data.members[0].id)) ||
                      data.message === '' ||
                      (data.message.type == 11 &&
                        data.message.from != authUser.sex)
                    )
                  "
                  :data="{
                    ...data,
                    members:
                      data.members[0].sex == authUser.sex && data.members[1]
                        ? [data.members[1]]
                        : [...data.members]
                  }"
                  imageSize="58px"
                  :ref="'room-' + index"
                />
                <hr
                  class="mb-0 mt-0 divide-message"
                  v-if="
                    index < firebaseListRoom.length - 1 &&
                      data.message !== '' &&
                      (data.message.type != 11 ||
                        data.message.from == authUser.sex)
                  "
                />
              </div>
            </div>
            <scroll-loader :loader-method="getList" :loader-disable="disable">
            </scroll-loader>
          </div>
          <div class="no-member" v-if="checkNoMsg === 0">
            <div class="no-member-msg">
              <img
                src="@/assets/image/bird.svg"
                alt="boss"
                width="60"
                height="60"
              />
              <h3 class="f-w6">新しいメッセージがありません</h3>
            </div>
          </div>
          <CardFooter ref="footer" prefer="message" />
          <transition name="slide-fade">
            <MessageSearch
              v-if="showSearch"
              v-model="showSearch"
              @show-search="changeShowSearchStatus"
            />
          </transition>
        </div>
      </template>
    </AppContainer>
  </div>
</template>

<script>
import CardFooter from "@/components/CardFooter.vue";
import HeaderCommon from "@/views/Common/HeaderCommon.vue";
import MessageFilter from "./MessageFilter.vue";
import MessageListItem from "./MessageListItem.vue";
import MessageSearch from "./MessageSearch.vue";
import { mapActions, mapGetters } from "vuex";
import {
  SYSTEM_ROOM_TYPE,
  INDIVIDUAL_ROOM_TYPE,
  GROUP_ROOM_TYPE
} from "@/constants/message";

export default {
  name: "MessageList",
  components: {
    CardFooter,
    HeaderCommon,
    MessageFilter,
    MessageListItem,
    MessageSearch
  },
  metaInfo() {
    return {
      title: "メッセージ一覧",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      disable: false,
      showSearch: false,
      type: [SYSTEM_ROOM_TYPE, INDIVIDUAL_ROOM_TYPE, GROUP_ROOM_TYPE],
      checkNoMsg: null,
      isGetting: false
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading",
      authUser: "auth/user",
      firebaseListRoom: "firebase/listRoom",
      listUserIdsBlock: "auth/userIdsBlock",
      firebaseUnread: "firebase/unread"
    })
  },
  watch: {
    firebaseListRoom() {
      this.isGetting = false;
      if (
        Object.keys(this.firebaseListRoom).length ===
          this.firebaseUnread.length ||
        Object.keys(this.firebaseListRoom).length < 10 ||
        Object.keys(this.firebaseListRoom).length % 10 !== 0
      ) {
        this.disable = true;
      } else {
        this.disable = false;
      }

      setTimeout(() => {
        if (this.firebaseListRoom.length > 0) {
          this.checkNoMsg = 1;
        }
      }, 2200);
    },
    firebaseUnread() {
      if (
        Object.keys(this.firebaseListRoom).length ===
          this.firebaseUnread.length ||
        Object.keys(this.firebaseListRoom).length < 10 ||
        Object.keys(this.firebaseListRoom).length % 10 !== 0
      ) {
        this.disable = true;
      } else {
        this.disable = false;
      }
    }
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    await this.getUserIdsBlock();
    await this.getListRoom({
      userId: this.authUser.user_id,
      type: this.type
    }).then(data => {
      setTimeout(() => {
        if (data.length === 0) {
          this.checkNoMsg = 0;
        } else {
          this.checkNoMsg = 1;
        }
      }, 2200);
    });
    await this.getUnread({
      userId: this.authUser.user_id
    });
    await this.setRoomId(null);
    this.$store.dispatch("common/setIsLoading", false);
  },
  methods: {
    ...mapActions("firebase", ["setRoomId", "getListRoom", "getUnread"]),
    ...mapActions("auth", ["getUserIdsBlock"]),

    getList() {
      if (this.firebaseListRoom[this.firebaseListRoom.length - 1]) {
        this.isGetting = true;
        this.getListRoom({
          userId: this.authUser.user_id,
          type: this.type,
          lastRoom: this.firebaseListRoom[this.firebaseListRoom.length - 1].id
        }).then(() => {
          setTimeout(() => {
            if (this.isGetting) {
              this.disable = true;
            }
          }, 3000);
        });
      }
    },
    changeShowSearchStatus() {
      this.showSearch = !this.showSearch;
    },
    chooseType(data) {
      this.checkNoMsg = 1;
      this.type = data;
      this.$root.$refs.loading.start();
      this.getListRoom({
        userId: this.authUser.user_id,
        type: this.type
      }).then(data => {
        setTimeout(() => {
          if (data.length === 0) {
            this.checkNoMsg = 0;
          }
        }, 2200);
      });
      this.$root.$refs.loading.finish();
    },

    checkShowMessage(data) {
      if (data.length > 0) {
        let checkRoomNotShow = 0;
        let checkRoomShow = 0;
        data.forEach(room => {
          if (
            (room.type === 2 &&
              room.members &&
              room.members[0] &&
              this.listUserIdsBlock.includes(room.members[0].id)) ||
            room.message === ""
          ) {
            checkRoomNotShow = 1;
          } else {
            checkRoomShow = 1;
          }
        });
        if (checkRoomNotShow === 1 && checkRoomShow === 0) {
          this.checkNoMsg = 0;
        } else {
          this.checkNoMsg = 1;
        }
      } else {
        this.checkNoMsg = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.divide {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0.5;
}
.divide-message {
  margin-left: 0;
  margin-right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0.5;
}
.content-scroll {
  overflow-y: scroll;
  //padding: 42px 0 64px 0;
  overflow-x: hidden;
  position: relative;
  //height: calc(100vh - 64px);
  z-index: 0;
}

// .ms-layout {
// background-color: #f8f8f8;
// min-height: 100vh;
// }

.list-message {
  padding: 10px 19px;
  padding-top: 0;
}
.filter-message {
  padding: 0 0 15px;
}

.no-member {
  //margin-top: -60px !important;
  margin-left: 20px;
  margin-right: 20px;
  //position: absolute;
  .no-member-msg {
    //position: relative;
    float: left;
    img {
      border-radius: 50%;
    }
    h3 {
      display: inline-flex;
      margin-left: 12px;
      font-weight: 600;
      font-size: 14px;
      top: 2px;
      position: relative;
      color: #000000;
    }
  }
}
</style>
