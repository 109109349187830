<template>
  <div
    class="message-search-item d-flex align-items-center justify-content-between bg-white"
  >
    <div class="d-flex align-items-center">
      <AvatarBox
        imgUrl="http://www.ubuntufree.com/wp-content/uploads/2014/07/Pretty-Girl-Wallpaper-200x200.jpg"
        imgAlt="User"
        imgSize="29px"
      />
      <p class="f-w6 ml-3">ニックネーム</p>
    </div>

    <button class="btn p-0">
      <img :src="require('@/assets/image/remove2.svg')" alt="remove message" />
    </button>
  </div>
</template>

<script>
import AvatarBox from "../Common/AvatarBox.vue";
export default {
  name: "MessageSearchItem",
  components: {
    AvatarBox
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_fontFamily.scss";

.message-search-item {
  padding: 8px 15px;
  border-radius: 10px;
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
  .btn {
    img {
      width: 12px;
      display: block;
    }
  }
}
</style>
