<template>
  <div class="message-search-header d-flex align-items-center">
    <button class="btn btn-back pl-0" @click="changeShowSearchStatus">
      <img :src="require('@/assets/image/left-arrow.svg')" alt="back" />
    </button>
    <form class="d-flex align-items-center bg-white">
      <input
        type="text"
        v-on:input="updateKeyword($event.target.value)"
        placeholder="ニックネームで検索"
        class="f-w3"
        :value="keyword"
      />
      <button class="btn">
        <img :src="require('@/assets/image/search.svg')" alt="search" />
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "MessageSearchHeader",
  props: {
    keyword: {
      type: String
    }
  },
  methods: {
    changeShowSearchStatus() {
      this.$emit("show-search");
    },

    updateKeyword(value) {
      this.$emit("keyword", value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_fontFamily.scss";

.message-search-header {
  padding: 10px 19px;
  border-bottom: 1px solid #f3f3f3;

  button,
  input {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .btn-back {
    padding: 0 10px;
    img {
      width: 15px;
      display: block;
    }
  }

  form {
    height: 32px;
    border-radius: 16px;
    padding: 8px 15px;
    width: 100%;
    input {
      flex: 1;
      border: none;
      font-size: 14px;

      &::placeholder {
        color: #9c9c9c;
      }
    }

    button {
      padding: 0;
      img {
        display: block;
        width: 18px;
      }
    }
  }
}
</style>
