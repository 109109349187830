var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppContainer',{attrs:{"useBackground":!_vm.isLoading ? true : false,"useShadow":!_vm.isLoading ? true : false}},[(_vm.isLoading)?[_c('AppLoading')]:[_c('div',{staticClass:"ms-layout"},[_c('HeaderCommon',{ref:"header",attrs:{"title":"メッセージ一覧"},on:{"show-search":_vm.changeShowSearchStatus},model:{value:(_vm.showSearch),callback:function ($$v) {_vm.showSearch=$$v},expression:"showSearch"}}),_c('div',{staticClass:"content-scroll",style:([
            _vm.firebaseListRoom.length > 0 && _vm.checkNoMsg == 1
              ? { height: 'calc(100vh - 64px)' }
              : ''
          ]),attrs:{"id":"content-scroll"}},[_c('div',{staticClass:"filter-message"},[_c('MessageFilter',{on:{"emitType":function($event){return _vm.chooseType($event)}}})],1),(_vm.firebaseListRoom.length > 0 && _vm.checkNoMsg == 1)?_c('hr',{staticClass:"mt-0 mb-0 divide"}):_vm._e(),(_vm.firebaseListRoom.length > 0 && _vm.checkNoMsg == 1)?_c('div',{staticClass:"list-message"},_vm._l((_vm.firebaseListRoom),function(data,index){return _c('div',{key:index},[(
                  !(
                    (data.type === 2 &&
                      data.members &&
                      data.members[0] &&
                      _vm.listUserIdsBlock.includes(data.members[0].id)) ||
                    data.message === '' ||
                    (data.message.type == 11 &&
                      data.message.from != _vm.authUser.sex)
                  )
                )?_c('MessageListItem',{ref:'room-' + index,refInFor:true,attrs:{"data":Object.assign({}, data,
                  {members:
                    data.members[0].sex == _vm.authUser.sex && data.members[1]
                      ? [data.members[1]]
                      : [].concat( data.members )}),"imageSize":"58px"}}):_vm._e(),(
                  index < _vm.firebaseListRoom.length - 1 &&
                    data.message !== '' &&
                    (data.message.type != 11 ||
                      data.message.from == _vm.authUser.sex)
                )?_c('hr',{staticClass:"mb-0 mt-0 divide-message"}):_vm._e()],1)}),0):_vm._e(),_c('scroll-loader',{attrs:{"loader-method":_vm.getList,"loader-disable":_vm.disable}})],1),(_vm.checkNoMsg === 0)?_c('div',{staticClass:"no-member"},[_c('div',{staticClass:"no-member-msg"},[_c('img',{attrs:{"src":require("@/assets/image/bird.svg"),"alt":"boss","width":"60","height":"60"}}),_c('h3',{staticClass:"f-w6"},[_vm._v("新しいメッセージがありません")])])]):_vm._e(),_c('CardFooter',{ref:"footer",attrs:{"prefer":"message"}}),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showSearch)?_c('MessageSearch',{on:{"show-search":_vm.changeShowSearchStatus},model:{value:(_vm.showSearch),callback:function ($$v) {_vm.showSearch=$$v},expression:"showSearch"}}):_vm._e()],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }